import logo from './logo.svg';
import './App.css';
import { AuthProvider } from './components/Contexts/AuthContexts';
import Router from './components/Routes/Routes';

function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
}

export default App;
