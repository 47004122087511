import React from 'react';
import { lazy } from "react";
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../PrivateRoutes/PrivateRoutes';

const Reports = lazy(() => import("../Views/Reports/Reports"));
const Slippage = lazy(() => import("../Views/Slippage/Slippage"));
const Login = lazy(() => import("../Views/Login/Login"));

const Router = () => {
    return (
        <>
            <Routes >
                <Route exact path='/reports' element={<PrivateRoute />}>
                    <Route exact path='/reports' element={<Reports />} />
                </Route>
                <Route exact path='/slippage' element={<PrivateRoute />}>
                    <Route exact path='/slippage' element={<Slippage />} />
                </Route>
                <Route path="/login" element={<Login />} />
            </Routes >
        </>
    );
}

export default Router;